<template>
  <div class="sidebar-wrap">
    <div class="user-info-wrap">
      <div class="user-avatar-wrap">
        <img :src="userInfo.avatar" :alt="userInfo.name">
      </div>
      <div class="user-name">{{userInfo.name}}</div>
      <div class="user-id">ID:{{userInfo.id}}</div>
      <div class="user-type">
        <i></i>
        <span>{{userIdentity}}</span>
      </div> 
    </div>
    <div class="sidebar-nav">
      <el-menu :unique-opened="true" @open="handleOpen" @close="handleClose" active-text-color="#f39f3d">
        <el-submenu index="1">
          <template slot="title">
            <span>账户管理</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="1-1" @click="toUserInfo">个人资料</el-menu-item>
            <el-menu-item index="1-2" @click="toAccountSecurity">账户安全</el-menu-item>
            <el-menu-item index="1-3" @click="toUserAddress">收货地址</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
         
        
        <el-menu-item index="5">
          <span slot="title" @click="orderManagement">订单管理</span>
        </el-menu-item>
       
        
        <el-menu-item index="9">
          <span slot="title" @click="toShopCart">购物车</span>
        </el-menu-item>
         
        <el-menu-item index="11">
          <span slot="title" @click="toCollectionRecord">我的收藏</span>
        </el-menu-item>
        
      </el-menu>
    </div>
  </div>
</template>

<script>
  import dump from "@/util/dump";
  export default {
    data(){
      return {
        userInfo: {}
      }
    },
    created() {
      this.init();
    },
    computed:{
      userIdentity(){
        let identity = "";
        switch(this.userInfo.roleId){
          case '3': identity = '注册用户';
          break;
          case '5': identity = '季会员';
          break;
          case '6': identity = '年会员';
          break;
          case '38e045588e0e4ce19b80ca40f5325934': identity = '高级会员';
          break;
          case 'de9de2f006e145a29d52dfadda295353': identity = '运营商';
          break;
          case '4': identity = '会员';
          break;
          case '7': identity = '子账号';
          break;
        }
        return identity;
      }
    },
    methods: {
      init(){
        this.userInfo.name = localStorage.getItem("name");
        this.userInfo.token = localStorage.getItem("token");
        this.userInfo.id = localStorage.getItem("phone");
        this.userInfo.roleId = localStorage.getItem("role_id");
        this.userInfo.avatar = localStorage.getItem("userAvatar") ? localStorage.getItem("userAvatar") : "./icon/user-default-avatar.jpg";
      },
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
    
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      toUserInfo(){
        const params = {
          type: 1,
          link: "/usercenter/userinfo"
        }
        dump.link(params);
      },
      toUserAddress(){
        dump.link({
          type: 1,
          link: "/usercenter/receivingAddress"
        });
      },
      // 跳转到VIP特权页
      toVipPrivilege(){
        dump.link({
          type: 1,
          link: "/usercenter/packageResources"
        });
      },
      // 跳转到子账号管理
      toSubAccountManagement(){
        dump.link({
          type: 1,
          link: "/usercenter/subAccountManagement"
        });
      },
      orderManagement(){
        dump.link({
          type: 1,
          link: "/usercenter/orderManagement"
        });
      },
      // 跳转到资产管理页
      toAssetManagement(){
        dump.link({
          type: 1,
          link: "/usercenter/assetManagement"
        })
      },
      // 跳转到商品管理
      toCommodityManagement(){
        dump.link({
          type: 1,
          link: "/usercenter/commodityManagement"
        })
      },
      // 跳转到会员签到 
      toSignIn(){
        dump.link({
          type: 1,
          link: "/usercenter/signIn"
        })
      },
      // 跳转到账号安全
      toAccountSecurity(){
        
        dump.link({
          type: 1,
          link: "/usercenter/accountSecurity"
        })
      },
      // 跳转到购物车
      toShopCart(){
        this.$router.push( "/usercenter/shopCart")
        // dump.link({
        //   type: 3,
        //   link: "#/usercenter/shopCart"
        // })
      },
      // 跳转到我的收藏 
      toCollectionRecord(){
         this.$router.push("/usercenter/collectionRecord")
        // dump.link({
        //   type: 1,
        //   link: "/usercenter/collectionRecord"
        // })
      },
      // 跳转到作品中心
      toWorksCenter(){
        dump.link({
          type: 1,
          link: "/usercenter/worksCenter"
        })
      },
      // 跳转到下载记录页
      toDownloadRecord(){
        dump.link({
          type: 1,
          link: "/usercenter/downloadRecord"
        })
      },
      // 跳转到浏览记录页
      toBrowseRecords(){
        dump.link({
          type: 1,
          link: "/usercenter/browseRecords"
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  .sidebar-wrap{
    width: 14.0625%;
    background-color: #fff;
    .user-info-wrap{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 29px 0;
      .user-avatar-wrap{
        width: 37.0370%;
        border-radius: 100%;
        overflow: hidden;
      }
      .user-name{
        margin-top: 7px;
        font-size: 17px;
        color: $select-search-category-title;
      }
      .user-id{
        font-size: 17px;
        color: $font-default-foot-link-color;
      }
      .user-type{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 11px;
        i{
          display: block;
          width: 24px;
          height: 24px;
          background-image: url("~@/../public/icon/userCenter/userInfo/register-user-icon@2x.png");
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          margin-right: 7px;
        }
        span{
          font-size: 17px;
          color: $select-search-category-title;
        }
      }
      .join-vip{
        width: 74.0741%;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background-color: #f39f3d;
        border-radius: 3px;
        margin-top: 20px;
        font-size: 15px;
        color: #fff;
        cursor: pointer;
      }
    }
    .sidebar-nav{
      /deep/ .el-menu{
        border-right: 0;
        .el-submenu{
          .el-submenu__title{
            position: relative;
            height: 45px;
            line-height: 45px;
            span{
              font-size: 17px;
              color: $font-default-color;
            }
            &:hover{
              background-color: #F5F5F5;
            }
            
          }
          &.is-opened{
            .el-submenu__title{
              background-color: #F5F5F5;
              &:after{
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                width: 3px;
                height: 100%;
                background-color: #f39f3d;
              }
            }
          }
          .el-menu-item-group__title{
            display: none;
          }
          .el-menu-item{
            height: 45px;
            line-height: 45px;
            padding: 0 !important;
            text-indent: 2em;
            font-size: 15px;
            color: $font-default-foot-link-color;
            &:focus,
            &:hover{
              outline: 0;
              background-color: #fff;
            }
            &:after{
              display: none;
            }
          }
        }
        .el-menu-item{
          height: 45px;
          line-height: 45px;
          span{
            font-size: 17px;
            color: $font-default-color;
          }
          &:hover{
            background-color: #F5F5F5;
          }
          &:focus{
            background-color: #fff;
          }
          &.is-active{
            background-color: #F5F5F5;
            span{
              color: #f39f3d;
            }
            &:after{
              position: absolute;
              left: 0;
              top: 0;
              content: "";
              width: 3px;
              height: 100%;
              background-color: #f39f3d;
            }
          }
        }
        
      }
    }
  }
</style>
